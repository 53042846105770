var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "a-upload",
        {
          attrs: {
            name: "file",
            action: _vm.action,
            headers: _vm.headers,
            showUploadList: false,
            multiple: true,
            "before-upload": _vm.beforeUpload,
          },
          on: { change: _vm.handleChange },
        },
        [_vm._t("uploadSlot", null, { loading: _vm.loading })],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }